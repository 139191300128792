import {
  Grid,
  Button,
  Speedometer,
  MetricsTiles,
  themeColorMode,
  LinearBarGraph,
} from 'mui-core';
import {
  speedometerData,
  programMetricsData,
  noneApplicableText,
  noneApplicableFullText,
  programMarketDetailsData,
} from 'data/programs.json';
import {licenseTypeKeys} from 'config';
import {getGoBanner} from 'mui-core/utils';
import {numberInUSFormat} from 'core/utils';
import {ProgramMarketDetails} from './components';
import {styled, useTheme} from '@mui/material/styles';
import {GoMeterAsset, defaultBannerImage, goLogoWhite} from 'assets/images';
import {
  Box,
  Card,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

const StyledOutBox = styled(Box)(({theme}) => ({
  borderRadius: '50px',
  backgroundColor: themeColorMode(
    theme,
    '#D9D2F6',
    '#222', // background color impact
    // theme.palette.darkGray.main,
  ),
}));

const NoProgramDataBanner = styled(Box)(({theme, bgImage}) => ({
  padding: 40,
  height: 360,
  borderRadius: '50px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  alignItems: 'center',
  backgroundImage: `url(${goLogoWhite})`,
  backgroundColor: themeColorMode(theme, '#E8E2FF', '#444'),
  [theme.breakpoints.down('sm')]: {
    padding: 20,
    backgroundPosition: 'center',
    backgroundSize: '84%',
  },
  [theme.breakpoints.up('sm')]: {
    backgroundPosition: 'right',
    backgroundSize: 'auto 80%',
    backgroundOrigin: 'content-box',
  },
}));

const MeterRoundedContainer = styled(Box)(({theme}) => ({
  width: 425,
  height: 425,
  borderRadius: 350,
  position: 'absolute',
  display: 'block',
  backgroundColor: themeColorMode(
    theme,
    '#D9D2F6',
    '#222', // background color impact
    // theme.palette.darkGray.main,
  ),
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const {
  marketDemandLabel,
  marketSupplyLabel,
  marketDemandDescription,
  marketSupplyDescription,
  marketDemandDataSourcetext,
  marketSupplyDataSourcetext,
} = programMarketDetailsData;

const {
  medianSalaryLabel,
  avgAnnualCostLabel,
  programDurationLabel,
  medianSalaryDescription,
  avgAnnualCostDescription,
  medianSalaryDataSourcetext,
  avgAnnualCostDataSourcetext,
  // jobOpeningLabel,
  // jobOpeningDescription,
  // jobOpeningDataSourcetext,
} = programMetricsData;

const {
  credentialValueLabel,
  credentialValueTitle,
  credentialValueQuestions: {
    credentialValueQuestionsHeading,
    credentialValueQuestions1,
    credentialValueQuestions2,
    credentialValueQuestions3,
    credentialValueQuestions4,
    credentialValueQuestions5,
    credentialValueMeasureText,
  },
  credentialValueDescription1,
  credentialValueDescription2,
} = speedometerData;

const questions = [
  credentialValueQuestions1,
  credentialValueQuestions2,
  credentialValueQuestions3,
  credentialValueQuestions4,
  credentialValueQuestions5,
];

const customLabelPlacementValues = {
  okPlacement: 34,
  goodPlacment: {
    xs: -12,
    sm: -30,
    md: -2,
    lg: -25,
  },
  greatPlacement: 8,
};

const programBadgeContent = () => {
  return (
    <Stack spacing={2}>
      <Typography
        variant='body1'
        dangerouslySetInnerHTML={{
          __html: credentialValueDescription1,
        }}
      />
      <Typography
        variant='body1'
        dangerouslySetInnerHTML={{
          __html: credentialValueMeasureText,
        }}
      />
      <Box display='flex' justifyContent='center' alignItems='center'>
        <img width='209' height='109' src={GoMeterAsset} alt='GoMeter-asset' />
      </Box>
      <Typography
        variant='body1'
        dangerouslySetInnerHTML={{
          __html: credentialValueDescription2,
        }}
      />
      <Typography
        variant='body1'
        dangerouslySetInnerHTML={{
          __html: credentialValueQuestionsHeading,
        }}
      />
      <Typography color='text.primary' component='ul'>
        {questions.map((question, index) => (
          <Typography
            key={index}
            component='li'
            variant='body1'
            color='text.primary'>
            {question}
          </Typography>
        ))}
      </Typography>
    </Stack>
  );
};

const Banner = ({programData = {}, token, openLogin, withCarousel = false}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    institute_details,
    award_type_category,
    credential_currency_details: {
      ave_cost = null,
      ave_wage = null,
      min_wage = null,
      max_wage = null,
      data_exist = null,
      job_openings = null,
      credential_value = null,
      credential_demand = null,
      credential_supply = null,
    } = {},
    duration: {
      duration_days = null,
      duration_hours = null,
      duration_weeks = null,
      duration_years = null,
      duration_months = null,
    } = {},
    program_details: {program_name: programOfStudy = ''} = {},
  } = programData || {};

  const isCriminalJustice =
    institute_details?.super_type_name === licenseTypeKeys?.criminalJustice;

  let durationValue = null;
  let unitLabel = null;

  if (duration_years) {
    unitLabel = `Year${duration_years > 1 ? 's' : ''}`;
    durationValue = duration_years;
  } else if (duration_months) {
    unitLabel = `Month${duration_months > 1 ? 's' : ''}`;
    durationValue = duration_months;
  } else if (duration_weeks) {
    unitLabel = `Week${duration_weeks > 1 ? 's' : ''}`;
    durationValue = duration_weeks;
  } else if (duration_hours) {
    unitLabel = `Hour${duration_hours > 1 ? 's' : ''}`;
    durationValue = duration_hours;
  } else if (duration_days) {
    unitLabel = `Day${duration_days > 1 ? 's' : ''}`;
    durationValue = duration_days;
  } else {
    return durationValue;
  }

  const getCostByAwardType = award => {
    switch (award) {
      case 'Grad': {
        return max_wage;
      }
      case 'AA/AS':
      case 'Certificate': {
        return min_wage;
      }
      case 'BA/BS': {
        return ave_wage;
      }
      default:
        return ave_wage;
    }
  };
  // const minAvgCal = data_exist ? ave_wage : min_wage;
  const minAvgCal = getCostByAwardType(award_type_category);
  const medianSalary = {
    min_wage: min_wage
      ? `$${numberInUSFormat(Math.round(min_wage))}`
      : noneApplicableFullText,
    ave_wage: ave_wage
      ? `$${numberInUSFormat(Math.round(ave_wage))}`
      : noneApplicableFullText,
    max_wage: max_wage
      ? `$${numberInUSFormat(Math.round(max_wage))}`
      : noneApplicableFullText,
  };

  const programMetricsDetails = [
    // {
    //   label: jobOpeningLabel,
    //   description: jobOpeningDescription,
    //   dataSourcetext: jobOpeningDataSourcetext,
    //   valueNumber: job_openings,
    //   value: job_openings
    //     ? `${numberInUSFormat(job_openings)}`
    //     : noneApplicableText,
    // },
    {
      label: programDurationLabel,
      description: medianSalaryDescription,
      dataSourcetext: durationValue,
      valueNumber: durationValue,
      unitLabel: unitLabel,
    },
    {
      label: medianSalaryLabel,
      description: medianSalaryDescription,
      dataSourcetext: medianSalaryDataSourcetext,
      valueNumber: minAvgCal,
      valueObj: medianSalary,
    },
    {
      label: avgAnnualCostLabel,
      description: avgAnnualCostDescription,
      dataSourcetext: avgAnnualCostDataSourcetext,
      valueNumber: ave_cost,
      value: ave_cost ? `$${numberInUSFormat(ave_cost)}` : noneApplicableText,
    },
  ];

  const ProgramProgressData = [
    {
      label: marketDemandLabel,
      progressValue: credential_demand,
      marketDescription: marketDemandDescription,
      dataSourcetext: marketDemandDataSourcetext,
    },
    {
      label: marketSupplyLabel,
      progressValue: credential_supply,
      marketDescription: marketSupplyDescription,
      dataSourcetext: marketSupplyDataSourcetext,
    },
  ];

  const showOnlyBannerImage =
    !ave_cost &&
    !ave_wage &&
    !job_openings &&
    !credential_demand &&
    !credential_supply
      ? true
      : false;

  const getProgramBanner = () => {
    if (programData?.banner_cloudinary) {
      return getGoBanner(programData.banner_cloudinary);
    } else {
      return defaultBannerImage;
    }
  };

  return (
    <Paper
      sx={{borderRadius: '50px', mt: 2, mb: withCarousel ? 2 : 10}}
      elevation={3}>
      {programOfStudy ? (
        <StyledOutBox p={5}>
          <Grid spacing={{xs: 2}} container>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <ProgramMarketDetails programData={programData} />
              {!isMobile && (
                <Box ml={2}>
                  <LinearBarGraph ProgressData={ProgramProgressData} />
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              sx={{position: 'relative'}}>
              {!token && (
                <Box
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    zIndex: 20,
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}>
                  <Card
                    variant='outlined'
                    sx={{
                      zIndex: 3,
                      backgroundColor: '#ffffff70',
                      border: '1px solid ',
                      p: 3,
                    }}>
                    <Button
                      sx={{color: 'primary.dark', fontWeight: 700}}
                      onClick={openLogin ? openLogin : () => {}}>
                      Login
                    </Button>
                    <Typography component='span' color='text.primary'>
                      to View Score
                    </Typography>
                  </Card>
                </Box>
              )}
              <Stack
                alignItems='center'
                justifyContent='center'
                sx={!token ? {filter: 'blur(10px)'} : {}}>
                <MeterRoundedContainer />
                <Speedometer
                  showBadge={true}
                  showDialValue={false}
                  dialValue={credential_value}
                  badgeData={programBadgeContent()}
                  badgeLabel={credentialValueLabel}
                  customLabelPlacement={customLabelPlacementValues}
                  tooltipObj={{label: credentialValueTitle, variant: 'h4'}}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              {isMobile && (
                <Box mb={{xs: 3, sm: 3, md: 0}}>
                  <LinearBarGraph ProgressData={ProgramProgressData} />
                </Box>
              )}
              <Stack
                height='100%'
                alignItems='center'
                spacing={{xs: 2, md: 2}}
                direction={{sm: 'row', lg: 'column'}}
                justifyContent={{sm: 'center', md: 'start', lg: 'center'}}>
                <MetricsTiles
                  metricsData={programMetricsDetails}
                  blurData={!token}
                />
              </Stack>
            </Grid>
          </Grid>
        </StyledOutBox>
      ) : (
        <NoProgramDataBanner bgImage={getProgramBanner()}>
          <Box
            p={2}
            height='auto'
            borderRadius={4}
            width={{sm: '100%', md: '75%'}}
            sx={
              {
                // boxShadow: `1px 2px 8px ${theme.palette.grayText.main}`,
                // backgroundColor:
                //   theme.palette.mode === 'light'
                //     ? 'white'
                //     : alpha(theme.palette.background.paper, 0.75),
              }
            }>
            <ProgramMarketDetails
              programData={programData}
              isCriminalJustice={isCriminalJustice}
            />
          </Box>
        </NoProgramDataBanner>
      )}
    </Paper>
  );
};

export default Banner;
