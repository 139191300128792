export const getBusinessPartners = state =>
  state?.businessPartners?.businessPartnersList;

export const getBusinessPartnerDetails = state =>
  state?.businessPartners?.businessPartnerDetails;

export const getBecomeBusinessPartnerData = state =>
  state?.businessPartners?.becomeBusinessPartner;

export const getIndustryList = state => state?.businessPartners?.industryList;

// nonprofit companies
export const getNonProfitCompanyList = state =>
  state?.businessPartners?.nonProfitCompanyList;

export const getNonProfitSubTypeList = state =>
  state?.businessPartners?.nonProfitSubTypes;

export const getNonProfitCompanyDetails = state =>
  state?.businessPartners?.nonProfitCompanyDetails;

export const getNonProfitCompanyMembersList = state =>
  state?.businessPartners?.nonProfitCompanyMembersList;
